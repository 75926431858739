import React, {useState} from "react"
import {Link} from "gatsby"
import classNames from "classnames"

import Image from "../image"
import BlockContent from "../block-content"

const List = ({services}) => {
  const [service, setService] = useState(null)

  let
    imageClass = classNames({
      'pillar-active': service !== null
    }),
    titleClass = classNames({
      'pillar-active-title': service !== null
    });

  return(
    <React.Fragment>
      <div className="services--list">
        <div className="images-container grid-container-13">
        {service !== null ? (
          <Image className={imageClass} fluid={services[service].image.asset.fluid} alt="Experience Ubuntu services"/>
        ) : (
          <div style={{visibility: "hidden"}}>
            <Image fluid={services[0].image.asset.fluid}/>
          </div>
        )}
        </div>
        <div className="text-container grid-container-13">
          <div className="services-names">
            <Link to="/experiences"><h3 onMouseEnter={() => setService(0)} onMouseLeave={() => setService(null)} className="clickable">Experiences</h3></Link>
            <Link to="/yoga"><h3 onMouseEnter={() => setService(1)} onMouseLeave={() => setService(null)} className="clickable">Yoga</h3></Link>
            <Link to="/kaleidoscope"><h3 onMouseEnter={() => setService(2)} onMouseLeave={() => setService(null)} className="clickable">Kaleidoscope</h3></Link>
            <Link to="/consulting"><h3 onMouseEnter={() => setService(3)} onMouseLeave={() => setService(null)} className="clickable">Consulting</h3></Link>
          </div>
          <div className="services-info">
            {service !== null && (
              <React.Fragment>
                <h4 className={titleClass}>{services[service].title}</h4>
                <BlockContent className="description" blocks={services[service]._rawText || []}/>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <div className="services-list-small">
        <Link to="/experiences"><h3>Experiences</h3></Link>
        <Link to="/yoga"><h3>Yoga</h3></Link>
        <Link to="/kaleidoscope"><h3>Kaleidoscope</h3></Link>
        <Link to="/consulting"><h3>Consulting</h3></Link>
      </div>
    </React.Fragment>
  )
}

export default List