import React from "react"
import {graphql} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSection from "../components/headerSection"
import List from "../components/services/list"

export const query = graphql`
query {
  sanityServices {
    title
    description
    experiences {
      title
      _rawText
      image {
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }
    yoga {
      title
      _rawText
      image {
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }
    kaleidoscope {
      title
      _rawText
      image {
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }
    consulting {
      title
      _rawText
      image {
        asset {
          fluid(maxWidth: 1280) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }
  }
}
`

const Services = ({data}) => {
  const
    {title, description, experiences, yoga, kaleidoscope, consulting} = data.sanityServices,
    services = [experiences, yoga, kaleidoscope, consulting]

  return(
    <Layout>
      <SEO title="Services"/>

      <HeaderSection title={title} description={description} maxWidth={"773px"}/>

      <List services={services}/>

    </Layout>
  )
}

export default Services
